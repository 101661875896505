import React from 'react';
import './Terms.css';

function Terms() {
  return (
    <div className="terms-container">
      <h1>Vilkår og Betingelser</h1>
      
      <section>
        <h2>1. Innledning</h2>
        <p>
          Dette kjøpet er regulert av de nedenstående standard salgsbetingelser for forbrukerkjøp over Internett. 
          Forbrukerkjøp over internett reguleres hovedsakelig av avtaleloven, forbrukerkjøpsloven, markedsføringsloven, 
          angrerettloven og ehandelsloven, og disse lovene gir forbrukeren ufravikelige rettigheter.
        </p>
      </section>

      <section>
        <h2>2. Priser og Betaling</h2>
        <p>
          Alle priser er oppgitt i norske kroner (NOK) og inkluderer merverdiavgift (MVA). 
          Betaling gjennomføres via Vipps. Beløpet blir belastet når tjenesten er levert.
        </p>
      </section>

      <section>
        <h2>3. Levering av Tjenesten</h2>
        <p>
          Etter gjennomført betaling vil du motta ditt genererte søknadsbrev på e-post innen 24 timer. 
          Dersom det oppstår forsinkelser, vil du bli varslet om dette.
        </p>
      </section>

      <section>
        <h2>4. Angrerett</h2>
        <p>
          Du har 14 dagers angrerett fra dagen etter du mottok søknadsbrevet. 
          Angreretten bortfaller når tjenesten er fullført ved at søknadsbrevet er levert til deg.
        </p>
        <p>
          For å benytte angreretten må du gi oss beskjed på e-post: support@soknadsbrev.no innen 14 dager.
        </p>
      </section>

      <section>
        <h2>5. Personvern</h2>
        <p>
          Vi behandler dine personopplysninger i henhold til gjeldende personvernlovgivning. 
          Les mer i vår personvernerklæring om hvordan vi samler inn og bruker dine personopplysninger.
        </p>
      </section>

      <section>
        <h2>6. Klagerett og Tvisteløsning</h2>
        <p>
          Ved klager, ta kontakt med oss på christian.lovstad@gmail.com. Vi vil besvare din henvendelse så raskt som mulig, 
          og senest innen 14 dager.
        </p>
        <p>
          Hvis vi ikke kommer til enighet, kan du ta kontakt med Forbrukertilsynet for mekling. 
          Forbrukertilsynet er tilgjengelig på telefon 23 400 600 eller www.forbrukertilsynet.no.
        </p>
      </section>

      <section>
        <h2>7. Kontaktinformasjon</h2>
        <p>
          Devas AS<br />
          President Harbitz' gate 9, 0259 Oslo<br />
          Org.nr: 923 547 894<br />
          E-post: christian.lovstad@gmail.com
          Telefon: 98 49 20 52
        </p>
      </section>
    </div>
  );
}

export default Terms;